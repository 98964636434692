import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import css from "./ModalRestablecerVerificacionClassfy.module.css"

const ModalRestablecerVerificacionClassfy = ({ open, onClose, onSubmit }) => (
  <Dialog onClose={onClose} open={open} fullWidth maxWidth="sm">
    <DialogTitle>Verificar email</DialogTitle>
    <DialogContent>
      <Alert
        icon={<i className="material-icons">warning</i>}
        className={css.alert}
        severity="warning"
      >
        Estás a punto de mandar un email al cliente para que verifique su email.
        <br /><br />
        Al recibir este email, el cliente verificará su email y podrá establecer una contraseña de acceso a Classfy.
      </Alert>
    </DialogContent>
    <DialogActions>
      <Button color="error" onClick={onClose}>
        Cancelar
      </Button>
      <Button 
        color="primary" 
        onClick={onSubmit} 
        >
        Continuar
      </Button>
    </DialogActions>
  </Dialog>
)

export default ModalRestablecerVerificacionClassfy
