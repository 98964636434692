import { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import LeadHeader from "../../../components/LeadHeader/LeadHeader"
import DatosAcceso from "./components/DatosAcceso/DatosAcceso"
import DatosSeguridad from "./components/DatosSeguridad/DatosSeguridad"
import PageLoading from "../../../components/PageLoading/PageLoading"
import Accesos from "./components/Accesos/Accesos"
import ConfigurarAcceso from "./components/ConfigurarAcceso/ConfigurarAcceso"
import useLead from "../../../hooks/useLead"
import { useNotification } from "../../../context/NotificationManager/NotificationManager"
import ModalEditarEmailAccesoClassfy from "../../../modals/ModalEditarEmailAccesoClassfy/ModalEditarEmailAccesoClassfy"
import ModalEditarPasswordAccesoClassfy from "../../../modals/ModalEditarPasswordAccesoClassfy/ModalEditarPasswordAccesoClassfy"
import ModalRestablecerPasswordAccesoClassfy from "../../../modals/ModalRestablecerPasswordAccesoClassfy/ModalRestablecerPasswordAccesoClassfy"
import ModalEditarAccesosClassfy from "../../../modals/ModalEditarAccesosClassfy/ModalEditarAccesosClassfy"
import ModalRestablecerVerificacionClassfy from "../../../modals/ModalRestablecerVerificacionClassfy/ModalRestablecerVerificacionClassfy"
import {
  createAccesoClassfy,
  getAccesoClassfy,
  updateAccesoAccesos,
  updateAccesoEmail,
  updateAccesoPassword,
  updateRestablecerPassword,
  updateRestablecerVerificacion,
} from "../../../utils/api/accesoClassfy"
import css from "./Classfy.module.css"

const Classfy = () => {

  const { id } = useLead()
  const queryClient = useQueryClient()
  const notification = useNotification()

  const [isOpenDatosAcceso, setIsOpenDatosAcceso]= useState(false)
  const [isOpenDatosSeguridad, setIsOpenDatosSeguridad]= useState(false)
  const [isOpenAccesos, setIsOpenAccesos]= useState(false)
  const [isOpenRestablecerPassword, setIsOpenRestablecerPassword] = useState(false)
  const [isOpenRestablecerAcceso, setIsOpenRestablecerAcceso] = useState(false)


  const { isLoading, data: accesoLead = {} } = useQuery({
    queryKey: ["classfy", "acceso-classfy", id],
    queryFn: () => getAccesoClassfy(id)
      .catch((err) => {
        // Este es un error conocido, lo ignoramos
        if (err === "No se ha encontrado ningún documento con los datos especificados") return {}
        notification.error({ title: "Error obteniendo los datos de Classfy", content: err })
        return {}
      }),
  })
 
  const { isPending: isCreatingAccess, mutate: crearAcceso } = useMutation({
    mutationFn: createAccesoClassfy,
    onSuccess: () => {
      notification.success({ title: "Acceso creado", content: "Se ha creado el acceso correctamente", })
      queryClient.invalidateQueries({ queryKey: ["classfy", "acceso-classfy", id] })

    },
    onError: (err) => {
      notification.error({ title: "Error creando acceso a Classfy", content: err })
    }
  })
 
  const { isPending: isDatosAccesoUpdating, mutate: updateLeadEmail } = useMutation({
    mutationFn: updateAccesoEmail,
    onSuccess: () => {
      notification.success({ title: 'Email actualizado', content: 'Email actualizado correctamente' })
      queryClient.invalidateQueries({ queryKey: ["classfy", "acceso-classfy", id] })
      setIsOpenDatosAcceso(false)
    },
    onError: err => {
      notification.error({ title: 'Error actualizando email', content: err })
    },
  })

  const { isPending: isDatosPasswordUpdating, mutate: updateLeadPassword } = useMutation({
    mutationFn: updateAccesoPassword,
    onSuccess: () => {
      notification.success({ title: 'Contraseña actualizada', content: 'Se ha actualizado la contraseña de acceso' })
      queryClient.invalidateQueries({ queryKey: ["classfy", "acceso-classfy", id] })
      setIsOpenDatosSeguridad(false)
    },
    onError: err => {
      notification.error({ title: 'Error actualizando contraseña', content: err })
    },
  })

  const { isPending: isAccesosUpdating, mutate: updateLeadAcceso } = useMutation({
    mutationFn: updateAccesoAccesos,
    onSuccess: () => {
      notification.success({ title: 'Accesos actualizados', content: 'Se han actualizado los accesos del lead' })
      queryClient.invalidateQueries({ queryKey: ["classfy", "acceso-classfy", id] })
      setIsOpenAccesos(false)
    },
    onError: err => {
      notification.error({ title: 'Error actualizando accesos', content: err })
    },
  })

  const { isPending: isRestablecerPasswordUpdating, mutate: updateAlumnoRestablecerPassword } = useMutation({
    mutationFn: updateRestablecerPassword,
    onSuccess: () => {
      notification.success({ title: 'Correo enviado', content: 'Se ha enviado el correo para restablecer la contraseña.' })
      setIsOpenRestablecerPassword(false)
    },
    onError: err => {
      notification.error({ title: 'Error enviando el email', content: err })
    },
  })

  const { isPending: isRestablecerVerificacionUpdating, mutate: updateAlumnoRestablecerVerificacion } = useMutation({
    mutationFn: updateRestablecerVerificacion,
    onSuccess: () => {
      notification.success({ title: 'Correo enviado', content: 'Se ha enviado el correo para verificar el email.' })
      setIsOpenRestablecerAcceso(false)
    },
    onError: err => {
      notification.error({ title: 'Error enviando el email', content: err })
    },
  })

  const handleCreateAccess = (email) => {
    if (isCreatingAccess) return
    crearAcceso({ feedback: id, email })
  }

  const handleUpdateDatosAcceso = ({ email, emailVerificado })=> {
    if (isDatosAccesoUpdating) return 
    updateLeadEmail({ id: accesoLead.id, email, verificado: emailVerificado })
  }

  const handleUpdateDatosSeguridad = (password)=> {
    if (isDatosPasswordUpdating) return
    updateLeadPassword({ id: accesoLead.id, password })
  }

  const handleUpdateRestablecerPassword = ()=> {
    if (isRestablecerPasswordUpdating) return
    updateAlumnoRestablecerPassword(accesoLead.id)
  }

  const handleUpdateRestablecerVerificacion = ()=> {
    if (isRestablecerVerificacionUpdating) return
    updateAlumnoRestablecerVerificacion(accesoLead.id)
  }
  
  const handleUpdateAccesos = ({ accesoApp, accesoClassfy, accesoBarbeibot })=> {
    if (isAccesosUpdating) return
    updateLeadAcceso({ 
      id: accesoLead.id, 
      acceso_app: accesoApp,
      acceso_classfy: accesoClassfy,
      acceso_barbeibot: accesoBarbeibot,
    })
  }


  const isAcceso = accesoLead && accesoLead.id

  return (
    <PageLoading isLoading={isLoading}>
      <div className={css.main}>
        <LeadHeader label="Classfy" />
        <div className={css.content}>
          {!isAcceso && (
            <div className={css.accordion}>
              <ConfigurarAcceso onSubmit={handleCreateAccess} />
            </div>
          )}
          {isAcceso && (
            <>
              <div className={css.accordion}>
                <DatosAcceso
                  accesoLead={accesoLead}
                  onEdit={()=> setIsOpenDatosAcceso(true)}
                />
              </div>
              <div className={css.accordion}>
                <DatosSeguridad 
                  accesoLead={accesoLead} 
                  onEdit={()=> setIsOpenDatosSeguridad(true)}
                  onResetPassword={()=> setIsOpenRestablecerPassword(true)}
                />
              </div>
              <div className={css.accordion}>
                <Accesos 
                  accesoLead={accesoLead} 
                  onEdit={()=> setIsOpenAccesos(true)}
                />
              </div>
              <ModalEditarEmailAccesoClassfy
                accesoCliente={accesoLead}
                open={isOpenDatosAcceso}
                onSubmit={handleUpdateDatosAcceso}
                onClose={()=> setIsOpenDatosAcceso(false)}
              />
              <ModalEditarPasswordAccesoClassfy
                open={isOpenDatosSeguridad}
                onSubmit={handleUpdateDatosSeguridad}
                onClose={()=> setIsOpenDatosSeguridad(false)}
              />
              <ModalEditarAccesosClassfy
                accesoCliente={accesoLead}
                open={isOpenAccesos}
                onSubmit={handleUpdateAccesos}
                onClose={()=> setIsOpenAccesos(false)}
              />
               <ModalRestablecerPasswordAccesoClassfy
                open={isOpenRestablecerPassword}
                onClose={()=> setIsOpenRestablecerPassword(false)}
                onSubmit={handleUpdateRestablecerPassword}
              />
                <ModalRestablecerVerificacionClassfy
                open={isOpenRestablecerAcceso}
                onClose={()=> setIsOpenRestablecerAcceso(false)}
                onSubmit={handleUpdateRestablecerVerificacion}
              />
            </>
          )}
        </div>
      </div>
    </PageLoading>
  )
}

export default Classfy
